
export default function Background() {


    return (
        <div className="h-screen w-screen z-10 absolute top-0 video-bg overflow-hidden">
            <video autoPlay loop muted width="100%">
                <source src="assets/video/mint.webm" type="video/webm" />
            </video>

            <div className="h-screen w-screen z-10 absolute top-0 video-bg overflow-hidden">

                    <img src="assets/images/fullbus.png" className="ui-bus" />
            </div>

            <div className="h-screen w-screen z-10 absolute top-0 video-bg overflow-hidden">
                <video autoPlay loop muted width="100%" className="ui-taffy">
                    <source src="assets/video/taffy-idle.webm" type="video/webm" />
                </video>
            </div>
        </div>
    );
}