
export default function Header() {


    return (
        <header className="site-header z-20 hidden">
            <div className="flex justify-end align-center items-center">

                <span className="minted">JUST MINTED?</span> <a href="https://campsolana.com/customize" target="_blank" rel="noreferrer" className="btn">CUSTOMIZE YOUR CAMPER</a>

            </div>
        </header>
    );
}